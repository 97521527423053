<template>
    <div class="title-flex">
        <h1>{{ etablissement.titre }}</h1>
        <p class="m-b-lg"><strong>Lien ical</strong> : <a :href="icalLink" target="_blank">{{ icalLink }}</a></p>
    </div>
    <div class="row">
        <DateSelector v-if="dataSource.id"
            :offre="dataSource"
            :height="600"
            :editing="editing"
        >
        </DateSelector>
    </div>
</template>

<script>
import axios from "axios";

import DateSelector from "@/components/DateSelector";
import useVuelidate from '@vuelidate/core'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "EtablissementCalendrier",
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      etablissement: {},
      reservations: [],
      editing: {
        allowAdding: false,
        allowDeleting: false,
        allowDragging: false,
        allowResizing: false,
        allowTimeZoneEditing: false,
        allowUpdating: false
      },
      editor: ClassicEditor,
      editorConfig : {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    axios
      .all([
        axios.get(process.env.VUE_APP_API + "/producteur/etablissement/" +
            this.$route.params.uuid),
        axios.get(process.env.VUE_APP_API + "/web/etablissement/reservations/" +
            this.$route.params.uuid)
      ])
      .then(
        axios.spread((...responses) => {
          this.etablissement = responses[0].data;
          this.reservations = responses[1].data;
          loading.close();
        })
      )
      .catch((error) => {
          console.log("ERROR", error);
      });
    },
  },
  computed: {
    icalLink() {
      return process.env.VUE_APP_TRINCH + '/etablissement/ical/' + this.$route.params.uuid
    },
    dataSource() {
      return {
        id: this.etablissement.id,
        titre: this.etablissement.titre,
        duree: 0,
        dates: this.reservations.map(d => {
          return {
            date: d.date_start,
            endDate: d.date_end
          }
        })
      }
    }
  },
  components: {
    DateSelector
  },
};
</script>